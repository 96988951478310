import React from 'react';
// import CodeImage from '../components/code';
// import { useMediaQuery } from 'react-responsive';
import Nav from '../components/nav';
// import MobileMenu from '../components/mobilemenu';
import { visualReferences } from '../data/visual-references';
import Tools from '../components/Tools/tools';
import GitCommitIcon from '../components/GitCommitIcon/gitCommitIcon';
import ReferenceImage from '../components/ReferenceImage/referenceImage';
import ReferenceTools from '../components/ReferenceTools/referenceTools';
import ReferenceVideo from '../components/ReferenceVideo/referenceVideo';
import './home.scss';
import './references.scss';

export default function References() {
    // const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' })
    return (
        <div>
            {/* {isDesktopScreen ? <Menu active='home' /> : <MobileMenu active='home' />} */}
            {/* {isDesktopScreen ? '' : <MobileMenu active='home' />} */}
            <Nav />
            <div className='references'>
                <h1 className='references-title'>References</h1>
                <Tools />
                {
                    visualReferences.map((reference, index) => 
                        <section key={ index } className="reference-wrap">
                            <div className="title top-space">
                                <GitCommitIcon />
                                <p className="italic bold reference-header">
                                    { reference.text }
                                </p>
                                <p className="italic reference-header">
                                    { reference.link ? <a className="reference-link" href={ reference.link } rel="noreferrer" target="_blank">{ reference.link }</a> : "" }
                                </p>
                                <p className="reference-description">{ reference.description ? reference.description : ''}</p>
                            </div>
                            <div className="references-date title">
                                <p className="italic thin">{ reference.month } { reference.year }</p>
                                <ReferenceTools data={ reference.tools } />
                            </div>
                            <br/>
                            {
                                reference.images 
                                    ? <ReferenceImage data={ reference.images } /> 
                                    : <ReferenceVideo data={ reference.videos } />
                            }
                        </section>
                    )
                }
            </div>
            
            {/* <Terminal /> */}
        </div>
    )
}
