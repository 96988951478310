import React from 'react';
import './referenceTools.scss';

export default function ReferenceTools ({ data }) { 
    const imgPath = `../../images/tools`;

    return (
        <div className="reference-tool">
            {
                data.map((tool, index) =>
                    <div className="reference-tool-icon" key={index}>
                        <img src={`${imgPath}/${tool}.svg`} alt={`${tool} icon`}/>
                        {console.log(`${imgPath}/${tool}.svg`)}
                    </div>
                )
            }
        </div>
    )
}
