import { Link } from 'gatsby';
import React, { useEffect } from 'react';
// import { gsap } from 'gsap';
// import { useMediaQuery } from 'react-responsive';
import DesignhorfLogo from './../images/designhorf.svg';
import './nav.scss';

export default function Nav() {
    useEffect(() => {}, []);
    // const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' })
    return (
        <nav className='nav'>
            <ul>
                <li>
                    <Link to='/'>
                        <DesignhorfLogo /> designhorf
                    </Link>
                </li>
                <li>
                    <div className='menu'>
                        <p className='menuItem'><Link to='/references'>References</Link></p>
                        {/* <p className='menuItem'>About</p> */}
                    </div>
                </li>
            </ul>
        </nav>
    )
}
