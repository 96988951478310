export const visualReferences = [
    // {
    //     'text': 'Logo design for Varda-Véd Ltd.',
    //     'year': '2014',
    //     'month': 'July',
    //     'tools': ['illustrator'],
    //     'images': ['vardaved']
    // },
    {
        'text': 'Grafbase - The data platform for developers',
        'link': 'https://grafbase.com/edge-gateway',
        'year': '2022',
        'tools': ['figma', 'framer'],
        'images': ['Grafbase-edge-gateway', 'Grafbase-branching-feature', 'Grafbase-edge-caching', 'Grafbase-career', 'Grafbase-career2', 'Grafbase-project-overview', 'Grafbase-branching', 'Grafbase-why', 'Grafbase-colors', 'Grafbase-buttons']
    },
    {
        'text': 'KeyFi PRO - DeFi application',
        'description': 'KeyFi PRO is a DeFi aggregator platform where users can track their portfolios across different networks (EVM) and platforms, and swap, borrow, deposit and earn tokens. Beside that they can get access for News Reader, create/manage Watchlists, SmartAlerts (tokens, Gas fees) and  create/manage Bundle (Multi-Wallet tracking)',
        'link': 'https://www.figma.com/proto/1dW2Ur4ewA33l9b0hyW098/KeyFi-PRO?page-id=4088%3A86466&node-id=4455%3A86129&scaling=min-zoom&starting-point-node-id=4455%3A86129',
        'year': '2021-2022',
        'tools': ['figma'],
        'images': ['keyfi-dashboard', 'keyfi-limitorder', 'keyfi-re', 'keyfi-periodictable', 'mobile-keyfi-borrow', 'mobile-keyfi-dashboard']
    },
    {
        'text': 'KeyFi PRO landing page',
        'link': 'https://keyfi.com/pro',
        'year': '2021',
        'month': 'Sept',
        'tools': ['figma'],
        'images': ['keyfi-website']
    },
    {
        'text': 'Selfkey Wallet - Part of Style Guide',
        'link': 'https://selfkey.org',
        'year': '2019-2020',
        'month': '',
        'tools': ['sketch'],
        'images': ['Selfkey-1-Colors-2', 'Selfkey-2-Typography-2', 'Selfkey-3-Forms-2']
    },
    {
        'text': 'Motion design for social media posts',
        'year': '2020-2021',
        'month': 'Dec-Jan',
        'tools': ['ae'],
        'videos': [
            {
                'source': 'https://player.vimeo.com/video/491689506'
            },
            {
                'source': 'https://player.vimeo.com/video/500032611'
            },
            {
                'source': 'https://player.vimeo.com/video/500037224'
            }
        ]
    },
    {
        'text': 'Stand out from the crowd instagram post designs',
        'year': '2020',
        'month': 'Nov',
        'tools': ['ps'],
        'images': ['designhorf-countdown', 'designhorf-001', 'designhorf-002', 'designhorf-003']
    },
    {
        'text': 'Unique Instagram feed + story design',
        'year': '2020',
        'month': 'Sept',
        'tools': ['ps', 'figma'],
        'images': ['instagram-feed-design', 'instagram-story-design', 'instagram-post']
    },
    {
        'text': 'New designhorf.com site concept',
        'year': '2020',
        'month': 'August',
        'tools': ['figma', 'sketch2'],
        'images': ['designhorf-new']
    },
    {
        'text': 'Cukorhal Visuals website design',
        'description': 'Website design for homestaging photography.',
        'year': '2020',
        'month': 'August',
        'tools': ['figma', 'sketch2'],
        'images': ['cukorhalvisuals', 'cukorhalvisuals-prices']
    },
    {
        'text': 'Cukorhal logo design',
        'link': 'https://www.facebook.com/cukorhalcopywriting',
        'year': '2020',
        'month': 'February',
        'tools': ['sketch2'],
        'images': ['cukorhal-logo']
    },
    {
        'text': 'Move 2 the Rock',
        'description': 'Informative website for people wishing to move to Malta. How to open a bank account, request TAX and NI number or open a bank account + salary calculator.',
        'link': 'http://bit.ly/move2TheRock',
        'year': '2018',
        'month': 'July',
        'tools': ['sketch2', 'camera', 'invision'],
        'images': ['m2tr3', 'm2tr4']
    },
    {
        'text': 'Tailored Tunes admin site',
        'year': '2017',
        'month': 'August',
        'tools': ['ps', 'invision'],
        'images': ['tatu-admin3', 'tatu-admin2', 'tatu-admin']
    },
    {
        'text': 'PRV Team',
        'link': 'http://www.prvteam.hu',
        'year': '2016',
        'month': 'December',	
        'tools': ['sketch2'],
        'images': ['prvteam-mockup']
    },
    {
        'text': 'Design opening & closing screen for Journey to my dreams daily vlog',
        'link': 'https://bit.ly/2FZPbrG',
        'year': '2016',
        'month': 'February',
        'tools': ['ps', 'camera'],
        'images': ['jtmd']
    },
    {
        'text': 'Layout design for Little Italy Restaurant',
        'year': '2016',
        'month': 'January',
        'tools': ['sketch2'],
        'images': ['littleitaly']
    },
    {
        'text': 'Monthly cover picture for Intelligent Waveforms',
        'year': '2015',
        'month': 'From December',
        'tools': ['ps'],
        'images': ['iw007', 'iw009', 'iw011', 'iw019', 'iw020']
    }
];
