import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default function ReferenceVideo ({ data }) {
    const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' });
    const videoWidth = isDesktopScreen ? 640 : 320;
    const videoHeight = isDesktopScreen ? 640 : 320;

    return (
        <div>
            {
                data.map((video, index) => 
                    <div className="reference-image" key={ index }>
                        <iframe title="video" src={video.source} width={video.width || videoWidth} height={video.height || videoHeight} frameborder="0"></iframe>
                    </div>
                )
            }
        </div>
    )
}